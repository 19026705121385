import { Injectable } from '@angular/core';
import { combineLatest, map, shareReplay } from 'rxjs';
import type { Observable } from 'rxjs';

import type { Slides } from '@app/presentation/slides/slides';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { TranslationService } from '@app/translation/translation.service';

import type { Lesson, LessonTags } from '../lessons';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { LessonsDataService } from './lessons-data.service';

export type LessonMap = Map<string, Lesson>;

const lessonSlugMap = (lesson: Lesson): [ string, Lesson ] => [ lesson.slug, lesson ];

// This service should be created by the root application injector.
@Injectable({ providedIn: 'root' })
export class LessonsService {
  /** All lessons available for current locale. */
  public readonly lessons$: Observable<Lesson[]>;
  public readonly lessonSlugMap$: Observable<LessonMap>;
  /** Lesson Categories for locale with available lessons for current locale. */
  public readonly lessonTags$: Observable<LessonTags[]>;

  /** Lists of lessons for locale, and those filtered by the locale. */
  private readonly _lessonSlugMap$: Observable<LessonMap>;

  constructor(
    private readonly lessonDataService: LessonsDataService,
    private readonly translationService: TranslationService,
  ) {
    this.lessons$ = combineLatest([
      this.lessonDataService.lessons$,
      this.translationService.updateTranslations$,
    ]).pipe(
      map(
        ([ rawLessons, currentLocale ]: [ Lesson[], string ]): Lesson[] => {
          // Convert locale code format to match locale format of lessons
          const normalizedLocale = this.translationService.normalizeLocale(currentLocale);

          // Hide any lessons that are not part of the current locale.
          return rawLessons.map((lesson: Lesson): Lesson => ({ ...lesson, hidden: lesson.locale !== normalizedLocale }));
        },
      ),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this._lessonSlugMap$ = this.lessons$.pipe(
      map((lessons: Lesson[]): LessonMap => new Map<string, Lesson>(lessons.map(lessonSlugMap))),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.lessonSlugMap$ = this._lessonSlugMap$;

    this.lessonTags$ = combineLatest([
      this.lessonDataService.lessonTags$,
      this._lessonSlugMap$,
    ]).pipe(
      map(([ lessonTags, lessonMap ]: [LessonTags[], LessonMap]): LessonTags[] => {
        // Filter out any tags that don't have any lessons that are active and not hidden.
        return lessonTags.flatMap((lessonTag: LessonTags): LessonTags[] => {
          const lessons = lessonTag.lessons
            .flatMap((lesson: Lesson): Lesson | undefined => lessonMap.get(lesson.slug))
            .filter((lesson: Lesson | undefined): lesson is Lesson => !!lesson && lesson.active && !lesson.hidden);

          if (lessons.length > 0) {
            return [
              {
                ...lessonTag,
                lessons,
              },
            ];
          }
          return [];
        });
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /**
   * Fetch a specific lesson by unique human friendly slug name.
   * This uses the shareReplay'd this._lessonSlugMap$ to reduce the number of requests we make to firestore
   */
  public getLessonBySlug(slug: string): Observable<Lesson> {
    return this._lessonSlugMap$.pipe(
      map((lessonMap: LessonMap): Lesson => {
        const lesson = lessonMap.get(slug);

        if (lesson == undefined) {
          throw new Error(`Lesson '${slug}' not found.`);
        }

        return lesson;
      }),
    );
  }

  public getRelatedLessonsBySlug(slugs: readonly string[]): Observable<Lesson[]> {
    return this._lessonSlugMap$.pipe(
      map((lessonMap: LessonMap): Lesson[] => {
        const filteredLessons: Lesson[] = [];
        for (const slug of slugs) {
          const lesson = lessonMap.get(slug);

          if (lesson == undefined) {
            throw new Error(`Lesson '${slug}' not found.`);
          }

          // Lessons that are hidden/inactive currently are to be skipped.
          if (lesson.active && !lesson.hidden) {
            filteredLessons.push(lesson);
          }
        }

        return filteredLessons;
      }),
    );
  }

  /** Fetch all Slides for a specific lesson by UUID. */
  public getSlidesForLessonById(lessonId: string): Observable<Slides[]> {
    return this.lessonDataService.getSlidesForLessonById(lessonId);
  }
}
