// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin: 1.5rem 0;
}

article {
  display: flex;
  justify-content: space-between;
}

.details {
  flex: 1 0;
  margin-right: 1rem;
}

.actions {
  display: none;
}
@media (min-width: 768px) {
  .actions {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    width: 8.125rem;
  }
}

.badge {
  --bs-badge-color: #54b7ae;
  --bs-badge-font-weight: 500;
  border: 1px solid #d6dbdc;
  width: 100%;
}
.badge .material-symbols-rounded {
  vertical-align: top;
  font-size: inherit;
}

.new-badge {
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 50px;
  background-color: #b37700;
  padding: 0.2rem 0.45rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 200;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
