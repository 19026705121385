import { Injectable } from '@angular/core';
import type { User } from '@angular/fire/auth';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  doc,
  docSnapshots,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
} from '@angular/fire/firestore';
import type { Observable, Subscription } from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  of,
  pairwise,
  ReplaySubject,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { LocalStorageService } from '@app/storage/local-storage.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';

import { AuthConfig } from './client-config/client-config'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { SessionDocument } from './heartbeat.service';

@Injectable({
  providedIn: 'root',
})
export class SessionListenerService {
  public session$: Observable<SessionDocument | undefined>;
  public sessionIdsSynced$: Observable<boolean>;

  private readonly _session$: ReplaySubject<SessionDocument | undefined>;
  private _sessionDocSubscription?: Subscription;
  private readonly _sessionIdsSynced$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly afFns: AngularFireFunctionsService,
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    private readonly firestore: Firestore,
    private readonly localStorage: LocalStorageService,
  ) {
    // Store and emit the last configured number of session updates
    this._session$ = new ReplaySubject(1);
    this.session$ = this._session$.asObservable();
    this.sessionIdsSynced$ = this._sessionIdsSynced$.asObservable();

    combineLatest([
      this.authService.isLoggedIn$.pipe(
        startWith(undefined),
        pairwise(),
        filter(
          ([ _prevLoggedIn, currLoggedIn ]: [boolean | undefined, boolean | undefined]): boolean =>
            currLoggedIn !== undefined,
        ),
      ),
      this.clientConfig.authConfig$.pipe(
        // Ensure authConfig$ emits an initial value
        startWith({ sessionTimeout: { enabled: false } } as AuthConfig),
        filter((authConfig: AuthConfig): boolean => !!authConfig.sessionTimeout.enabled),
      ),
    ])
      .pipe(
        distinctUntilChanged(
          (
            [ [ prevPrevLoggedIn, prevCurrLoggedIn ], prevAuthConfig ]: [[boolean | undefined, boolean | undefined], AuthConfig],
            [ [ currPrevLoggedIn, currCurrLoggedIn ], currAuthConfig ]: [[boolean | undefined, boolean | undefined], AuthConfig],
          ): boolean =>
            prevPrevLoggedIn === currPrevLoggedIn
            && prevCurrLoggedIn === currCurrLoggedIn
            && prevAuthConfig.sessionTimeout.enabled === currAuthConfig.sessionTimeout.enabled,
        ),
        switchMap(
          ([ [ prevLoggedIn, currLoggedIn ], _authConfig ]: [[boolean | undefined, boolean | undefined], AuthConfig]): Observable<void> => {
            if (currLoggedIn) {
              if (prevLoggedIn === false) {
                // User just logged in
                return this._createSession();
              } else if (prevLoggedIn === undefined) {
                // Initial emission on page load with user already logged in
                this._startSessionListener();
                return of();
              }
            }
            // User is not logged in
            this._stopSessionListener();
            this._sessionIdsSynced$.next(false);
            return of();
          },
        ),
      )
      .subscribe({
        error: (err: unknown): void => {
          console.error('SessionListenerService error:', err);
        },
      });
  }

  public createSession(): Observable<SessionDocument> {
    const createSession = this.afFns.httpsCallable<undefined, SessionDocument>('createSession');
    return createSession(undefined);
  }

  private _createSession(): Observable<void> {
    return this.createSession().pipe(
      tap((sessionDoc: SessionDocument): void => {
        this.localStorage.set('sessionId', sessionDoc.sessionId);
        this._sessionIdsSynced$.next(true);
        this._startSessionListener();
      }),
      switchMap((): Observable<void> => of(undefined)),
    );
  }

  private _listenToSessionChanges(): Observable<void> {
    return this.authService.currentUser$.pipe(
      take(1),
      switchMap((currentUser: User): Observable<void> => {
        const docRef = doc(this.firestore, `sessions/${currentUser.uid}`) as DocumentReference<SessionDocument>;

        return docSnapshots<SessionDocument>(docRef).pipe(
          // Continuously listen to session document changes
          switchMap((snapshot: DocumentSnapshot<SessionDocument>): Observable<void> => {
            if (!snapshot.exists()) {
              this._sessionIdsSynced$.next(false);
              return this.authService.logout(true);
            }

            const firebaseSession = snapshot.data();
            this._session$.next(firebaseSession);
            const localSessionId = this.localStorage.get('sessionId');
            const now = new Date();

            const sessionExpired = firebaseSession.expiresAt.toDate() < now;
            const sessionIdMismatch = localSessionId !== firebaseSession.sessionId;

            if (!sessionExpired && !sessionIdMismatch) {
              // Session is valid
              this._sessionIdsSynced$.next(true);
              return of();
            }
            // Session is invalid, log the user out
            this._sessionIdsSynced$.next(false);
            return this.authService.logout(true);
          }),
        );
      }),
    );
  }

  private _startSessionListener(): void {
    if (this._sessionDocSubscription) {
      // Already subscribed
      return;
    }

    this._sessionDocSubscription = this._listenToSessionChanges().subscribe({
      error: (err: unknown): void => {
        console.error('SessionListenerService error during session listening:', err);
      },
    });
  }

  private _stopSessionListener(): void {
    if (this._sessionDocSubscription) {
      this._sessionDocSubscription.unsubscribe();
      this._sessionDocSubscription = undefined;
    }
  }
}
