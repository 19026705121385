import { Injectable } from '@angular/core';
import type { Timestamp } from '@angular/fire/firestore';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  of,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AuthService } from '@app/auth/auth.service';

export interface SessionDocument {
  createdAt: number;
  expiresAt: Timestamp;
  lastActive: Timestamp;
  sessionId: string;
}

interface SessionStatus {
  expiresAt: Timestamp;
  lastActive: Timestamp;
}

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  private readonly _debounceTime: number = 2000;
  private readonly _eventSubject$: Subject<void> = new Subject<void>();
  // eslint-disable-next-line @stylistic/max-len
  private readonly _sessionStatusSubject$: BehaviorSubject<SessionStatus | undefined> = new BehaviorSubject<SessionStatus | undefined>(undefined);

  constructor(
    private readonly afFns: AngularFireFunctionsService,
    private readonly authService: AuthService,
  ) {
    combineLatest([ this.authService.isLoggedIn$, this._eventSubject$ ])
      .pipe(
        debounceTime(this._debounceTime),
        switchMap(([ isLoggedIn, _ ]: [ boolean, unknown ]): Observable<SessionDocument | undefined> => {
          if (!isLoggedIn) {
            return of();
          }
          return this.triggerDebounced();
        }),
        catchError((): Observable<undefined> => {
          return of();
        }),
      )
      .subscribe({
        next: (result: SessionDocument | undefined): void => {
          if (result) {
            this._sessionStatusSubject$.next({
              expiresAt: result.expiresAt,
              lastActive: result.lastActive,
            });
          }
        },
      });
  }

  /** Currently not working */
  public get sessionStatus$(): Observable<SessionStatus | undefined> {
    return this._sessionStatusSubject$.asObservable();
  }

  // Method to trigger an event to send a heartbeat
  public eventTrigger(): void {
    this._eventSubject$.next();
  }

  // Method to call the cloud function for updating the session
  public triggerDebounced(): Observable<SessionDocument> {
    const heartbeatTrigger = this.afFns.httpsCallable<undefined, SessionDocument>('updateSession');
    return heartbeatTrigger(undefined).pipe(
      catchError((err: unknown): Observable<never> => {
        if (err instanceof Error && err.message.includes('must be called while authenticated')) {
          return of(); // Return empty observable if user is not authenticated
        }
        return throwError((): Error => new Error(err as string));
      }),
    );
  }
}
